import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {PortableText} from '@portabletext/react'
import SubLayout from '../components/layouts/SubLayout'
import Seo from '../components/Seo'
import ArticleImage from '../components/portabletext/ArticleImage'

// Components for portabletext
const ptComponents = {
  block: {
    'normal+center': ({children}) => <p className='center'>{children}</p>
  },
  types: {
    articleImage: ArticleImage,
  }
}

// Main Component
const ArticlePage = ({data}) => {
  const {
    title,
    slug,
    featuredImage,
    _rawContent
  } = data.sanityArticle

  return (
    <SubLayout>
      <Seo title={title} pathname={`/${slug.current}`} />
      <StyledArticlePage>
        <h1>{title}</h1>
        <GatsbyImage image={getImage(featuredImage.asset)} alt={slug.current} />
        <div className="content">
          <PortableText
            value={_rawContent}
            components={ptComponents}
          />
        </div>
      </StyledArticlePage>
    </SubLayout>
  )
}

export const query = graphql`
  query ArticlePageQuery($id: String) {
    sanityArticle(id: {eq: $id}) {
      title
      slug { current }
      featuredImage {
        asset { gatsbyImageData(layout: FULL_WIDTH) }
      }
      _rawContent
    }
  }
`

const StyledArticlePage = styled.div`
  h1 {
    margin-bottom: 2rem;
  }

  .content {
    margin-top: 2rem;
    line-height: 1.5;

    a {
      color: var(--primary-color);
    }

    p {
      /* margin: 0.75rem 0; */
      margin: 1.25rem 0;

      &.center {
        margin: 0.75rem auto;
        max-width: 90%;
        text-align: center;
      }
    }

    ul, ol {
      margin-left: 2rem;

      li {
        margin: 0.5rem 0;
      }
    }

    img {
      max-width: 100%;

      &.center {
        display: block;
        width: fit-content;
        margin: auto;
      }
    }

    figure {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      max-width: 100%;

      &.center {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }

      figcaption {
        color: #888;
        font-size: 0.9rem;
      }
    }
  }
`

export default ArticlePage
